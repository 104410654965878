module.exports = {
  langs: ['en', 'id', 'jp', 'eu', 'my'],
  defaultLangKey: 'en',
  supportedCurrencies: ['USD', 'EUR', 'IDR', 'JPY', 'GBP', 'MYR'],
  langCurrency: {
    en: ['USD'],
    id: ['IDR'],
    jp: ['JPY'],
    eu: ['EUR', 'GBP'],
    my: ['MYR']
  },
  langlocales: {
    en: 'en',
    id: 'id',
    jp: 'jp',
    eu: 'en',
    my: 'en'
  },
  hrefLang: {
    en: 'en',
    id: 'id',
    jp: 'ja',
    eu: 'en',
    my: 'en'
  },
  storeRegions: [
    {
      value: 'jakarta',
      label: 'JAKARTA',
      currency: 'IDR'
    },
    {
      value: 'bodetabek',
      label: 'BODETABEK',
      currency: 'IDR'
    },
    {
      value: 'bandung',
      label: 'BANDUNG',
      currency: 'IDR'
    },
    {
      value: 'yogyakarta',
      label: 'YOGYAKARTA',
      currency: 'IDR'
    },
    {
      value: 'surabaya',
      label: 'SURABAYA',
      currency: 'IDR'
    },
    {
      value: 'bali',
      label: 'BALI',
      currency: 'IDR'
    },
    {
      value: 'lombok',
      label: 'LOMBOK',
      currency: 'IDR'
    },
    {
      value: 'makassar',
      label: 'MAKASSAR',
      currency: 'IDR'
    },
    {
      value: 'medan',
      label: 'MEDAN',
      currency: 'IDR'
    },
    {
      value: 'kualalumpur',
      label: 'KUALA LUMPUR',
      currency: 'MYR'
    },
    {
      value: 'selangor',
      label: 'SELANGOR',
      currency: 'MYR'
    }
  ],
  byPriceListObj: [
    {
      currency: 'IDR',
      locale: 'id',
      list: [
        {
          label: "IDR 200,000",
          path: 'low',
          maxPrice: 200000,
        },
        {
          label: "IDR 500,000",
          path: 'medium',
          maxPrice: 500000
        },
        {
          label: "IDR 1,000,000",
          path: 'high',
          maxPrice: 1000000
        }
      ]
    },
    {
      currency: 'USD',
      locale: 'en',
      list: [
        {
          label: "USD 20",
          path: 'low',
          maxPrice: 20
        },
        {
          label: "USD 50",
          path: 'medium',
          maxPrice: 50
        },
        {
          label: "USD 100",
          path: 'high',
          maxPrice: 100
        }
      ]
    },
    {
      currency: 'MYR',
      locale: 'my',
      list: [
        {
          label: "MYR 20",
          path: 'low',
          maxPrice: 20
        },
        {
          label: "MYR 50",
          path: 'medium',
          maxPrice: 50
        },
        {
          label: "MYR 100",
          path: 'high',
          maxPrice: 100
        }
      ]
    },
    {
      currency: 'JPY',
      locale: 'jp',
      list: [
        {
          label: "JPY 2000",
          path: 'low',
          maxPrice: 2000
        },
        {
          label: "JPY 5000",
          path: 'medium',
          maxPrice: 5000
        },
        {
          label: "JPY 10000",
          path: 'high',
          maxPrice: 10000
        }
      ]
    },
    {
      currency: 'EUR',
      locale: 'eu',
      list: [
        {
          label: "EUR 20",
          path: 'low',
          maxPrice: 20
        },
        {
          label: "EUR 50",
          path: 'medium',
          maxPrice: 50
        },
        {
          label: "EUR 100",
          path: 'high',
          maxPrice: 100
        }
      ]
    },
    {
      currency: 'GBP',
      locale: 'eu',
      list: [
        {
          label: "GBP 20",
          path: 'low',
          maxPrice: 20
        },
        {
          label: "GBP 50",
          path: 'medium',
          maxPrice: 50
        },
        {
          label: "GBP 100",
          path: 'high',
          maxPrice: 100
        }
      ]
    },
  ]
}